<template>
    <v-card
        class="mx-auto mb-12"
        style="background-color: unset;"
        elevation="0"
    >
        <h2 class="text-center">Contacto</h2>
        <v-form
            class="px-5"
            ref="form"
            v-model="valid"
            lazy-validation
        >
            <v-text-field
                v-model="phone"
                type="number"
                label="Teléfono"
            ></v-text-field>

            <v-text-field
                v-model="mobile"
                :rules="mobileRules"
                type="number"
                label="Celular"
                required
            ></v-text-field>

            <v-text-field
                v-model="email"
                :counter="emailMax"
                :maxlength="emailMax"
                :rules="emailRules"
                label="Correo Electrónico"
                required
            ></v-text-field>
        </v-form>
    </v-card>
</template>

<script>
import Storage from "@/modules/storage";
const store = new Storage();

export default {
    data() {
        return {
            mountedFinish: false,
            valid: false,
            phone: '',
            mobile: '',
            mobileMin: 0,
            mobileMax: 1,
            mobileRules: [
                v => !!v || 'El teléfono es requerido',
                v => (v && v >= this.mobileMin) || `El Celular debe ser un número superior a ${this.mobileMin}`,
                v => (v && v <= this.mobileMax) || `El Celular debe ser un número inferior a ${this.mobileMax}`,
            ],
            email: '',
            emailMin: 0,
            emailMax: 1,
            emailRules: [
                v => !!v || 'El Correo Electrónico es requerido',
                v => /.+@.+/.test(v) || 'El Correo Electrónico debe ser válido',
                v => (v && v.length >= this.emailMin) || `El Correo Electrónico debe tener al menos ${this.emailMin} letras`,
                v => (v && v.length <= this.emailMax) || `El Correo Electrónico no debe superar las ${this.emailMax} letras`,
            ],
        }
    },
    props: ['validate', 'default'],
    watch: {
        validate: function(value) {
            if (value) {
                this.$refs.form.validate();
            }
        },
        default: function(value) {
           if (value) {
               const checkMount = setInterval(() => {
                   if (this.mountedFinish) {
                        this.phone = value.phone;
                        this.mobile = value.mobile;
                        this.email = value.email;
                        clearInterval(checkMount);
                   }
               }, 100);
            } 
        }
    },
    updated() {
        this.$emit('valid', {
            step: 2,
            valid: this.valid,
            data: {
                phone: parseInt(this.phone),
                mobile: parseInt(this.mobile),
                email: this.email
            }
        })
    },
    async mounted() {
        const schemaData = await store.getData("schema");
        schemaData.forEach(schema => {
            if (schema.store === "contributor") {
                this.mobileMin = schema.data.mobile.min;
                this.mobileMax = schema.data.mobile.max;
                this.emailMin = 4;
                this.emailMax = 1024;
            }
        });
        this.mountedFinish = true;
    },
}
</script>