<template>
    <v-card
        class="mx-auto mb-12"
        style="background-color: unset;"
        elevation="0"
    >
        <h2 class="text-center">Datos Básicos</h2>
        <v-form
            class="px-5"
            ref="form"
            v-model="valid"
            lazy-validation
        >
            <v-select
                v-model="personType"
                :items="personTypes"
                :rules="personTypeRules"
                item-text="titleName"
                item-value="name"
                label="Tipo de Aportante"
                required
            ></v-select>

            <v-text-field
                v-model="name"
                :counter="nameMax"
                :maxlength="nameMax"
                :rules="nameRules"
                label="Nombre o Razón Social"
                required
            ></v-text-field>

            <v-text-field
                v-model="ID"
                :rules="IDRules"
                type="number"
                label="NIT o Cédula"
                required
            ></v-text-field>

            <v-autocomplete
                label="Actividad Económica"
                v-model="economicActivity"
                required
                :items="economicActivities"
                item-text="compile"
                item-value="_id"
                :rules="economicActivityRules"
            ></v-autocomplete>

            <v-autocomplete
                label="ARL"
                v-model="ARL"
                required
                :items="ARLS"
                item-text="name"
                item-value="_id"
                :rules="ARLRules"
            ></v-autocomplete>
        </v-form>
    </v-card>
</template>

<script>
import Storage from "@/modules/storage";
import { titleCase } from "@/modules/tools";

const store = new Storage();

export default {
    data() {
        return {
            mountedFinish: false,
            valid: false,
            economicActivity: '',
            economicActivityRules: [
                v => !!v || 'La Actividad Económica es requerida',
            ],
            economicActivities: [],
            ARL: '',
            ARLRules: [
                v => !!v || 'La ARL es requerida',
            ],
            ARLS: [],
            personType: '',
            personTypes: [],
            personTypeRules: [
                v => !!v || 'El Tipo de Aportante es requerido',
            ],
            name: '',
            nameMin: 0,
            nameMax: 1,
            nameRules: [
                v => !!v || 'El nombre es requerido',
                v => (v && v.length >= this.nameMin) || `El nombre debe tener al menos ${this.nameMin} letras`,
                v => (v && v.length <= this.nameMax) || `El nombre no debe superar las ${this.nameMax} letras`,
            ],
            ID: '',
            IDMin: 0,
            IDMax: 1,
            IDRules: [
                v => !!v || 'El NIT o Cédula es requerido',
                v => (v && v >= this.IDMin) || `El NIT o Cédula debe ser un número superior a ${this.IDMin}`,
                v => (v && v <= this.IDMax) || `El NIT o Cédula debe ser un número inferior a ${this.IDMax}`,
            ],
        }
    },
    props: ['validate', 'default'],
    watch: {
        validate: function(value) {
            if (value) {
                this.$refs.form.validate();
            }
        },
        default: function(value) {
           if (value) {
               const checkMount = setInterval(() => {
                   if (this.mountedFinish) {
                        this.economicActivity = value.economicActivity;
                        this.ARL = value.ARL;
                        this.personType = value.personType;
                        this.ID = value.ID;
                        this.name = value.name;
                        clearInterval(checkMount);
                   }
               }, 100);
            } 
        }
    },
    updated() {
        this.$emit('valid', {
            step: 1,
            valid: this.valid,
            data: {
                economicActivity: this.economicActivity,
                ARL: this.ARL,
                name: this.name,
                personType: this.personType,
                ID: parseInt(this.ID)

            }
        })
    },
    async mounted() {
        const schemaData = await store.getData("schema");
        const economicActivityData = await store.getData("economicActivity");
        const economicActivityGroupData = await store.getData("economicActivityGroup");
        const ARLData = await store.getData("ARL");
        schemaData.forEach(schema => {
            if (schema.store === "contributor") {
                this.IDMin = schema.data.ID.min;
                this.IDMax = schema.data.ID.max;
                this.nameMin = schema.data.name.min;
                this.nameMax = schema.data.name.max;
                schema.data.personType.enum.forEach(personType => {
                    this.personTypes.push({
                        titleName: titleCase(personType),
                        name: personType
                    });
                });
            }
        });
        economicActivityGroupData.sort(function(a, b) {
            return a.code.localeCompare(b.code);
        });
        economicActivityData.sort(function(a, b) {
            return a.code.localeCompare(b.code);
        });
        economicActivityGroupData.forEach(activityGroup => {
            activityGroup.name = titleCase(activityGroup.name);
            this.economicActivities.push({ header: activityGroup.name });
            economicActivityData.forEach(activity => {
                if (activityGroup._id === activity.economicActivityGroup) {
                    activity.name = titleCase(activity.name);
                    activity.compile = activity.code + ' - ' + activity.name;
                    activity.group = activityGroup.name;
                    this.economicActivities.push(activity); 
                }
            });
        });
        ARLData.sort(function(a, b) {
            return a.name.localeCompare(b.name);
        });
        ARLData.forEach(ARL => {
            this.ARLS.push(ARL);
        });
        this.mountedFinish = true;
    }
}
</script>