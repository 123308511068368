<template>
    <v-card
        class="mx-auto mt-6"
        max-width="700"
    >
        <v-card>
            <v-card-text>Usted se encuentra: Editando el Aportante <strong>{{ editObject.name }}</strong></v-card-text>
        </v-card>
        <v-stepper alt-labels v-model="e1">
            <v-stepper-header>
            <v-stepper-step
                :rules="[() => stepOne]"
                editable
                step="1"
                :complete="e1 > 1"
                @click="validateOne = true; validateTwo = true; validateThree = true"
            >
            </v-stepper-step>

            <v-divider></v-divider>

            <v-stepper-step
                :rules="[() => stepTwo]"
                editable
                :complete="e1 > 2"
                @click="validateOne = true; validateTwo = true; validateThree = true"
                step="2"
            >
            </v-stepper-step>

            <v-divider></v-divider>

            <v-stepper-step :rules="[() => stepThree]" editable step="3" @click="validateOne = true; validateTwo = true; validateThree = true">
            </v-stepper-step>
            </v-stepper-header>

            <v-stepper-items>
            <v-stepper-content step="1">
                <One @valid="validStep" :validate="validateOne" :default="editObject"></One>

                <v-btn
                color="primary"
                @click="e1 = 2; validateOne = true"
                >
                Siguiente
                </v-btn>

                <v-btn @click="rollbackLink" text>
                Cancelar
                </v-btn>
            </v-stepper-content>

            <v-stepper-content step="2">
                <Two @valid="validStep" :validate="validateTwo" :default="editObject"></Two>

                <v-btn
                color="blue-grey"
                class="mr-2 white--text"
                @click="e1 = 1; validateTwo = true"
                >
                Anterior
                </v-btn>

                <v-btn
                color="primary"
                @click="e1 = 3; validateTwo = true"
                >
                Siguiente
                </v-btn>

                <v-btn @click="rollbackLink" text>
                Cancelar
                </v-btn>
            </v-stepper-content>

            <v-stepper-content step="3">
                <Three @valid="validStep" :validate="validateThree" :default="editObject"></Three>

                <v-btn
                color="blue-grey"
                class="mr-2 white--text"
                @click="e1 = 2; validateThree = true"
                >
                Anterior
                </v-btn>

                <v-btn
                color="primary"
                @click="receiveData"
                :disabled="invalidForm"
                >
                Editar Aportante
                </v-btn>

                <v-btn @click="rollbackLink" text>
                Cancelar
                </v-btn>
            </v-stepper-content>
            </v-stepper-items>
        </v-stepper>
    </v-card>
</template>

<script>
import Swal from "sweetalert2";

import One from "./form/1.vue";
import Two from "./form/2.vue";
import Three from "./form/3.vue";

import Storage from "@/modules/storage";

const store = new Storage();

  export default {
    data () {
      return {
        editObject: {},
        data: {},
        e1: 1,
        invalidForm: true,
        stepOne: true,
        validateOne: false,
        stepTwo: false,
        validateTwo: false,
        stepThree: false,
        validateThree: false
      }
    },
    async mounted() {
        this.editObject = await store.getData("contributor", this.$route.params.object_id);
    },
    components: {
        One,
        Two,
        Three
    },
    methods: {
        validStep(value) {
            if (value.step === 1) {
                this.stepOne = value.valid
            }
            if (value.step === 2) {
                this.stepTwo = value.valid
            }
            if (value.step === 3) {
                this.stepThree = value.valid
            }
            for (let [key, value] of Object.entries(value.data)) {
                this.data[key] = value;
            }
            if (this.stepOne && this.stepTwo && this.stepThree) {
                this.invalidForm = false;
            } else {
                this.invalidForm = true;
            }
        },
        async receiveData() {
            Swal.fire({
                icon: "warning",
                title: "Actualizando Aportante",
                html:
                "Por favor espere mientras se actualiza los datos del Aportante</b> " +
                '<progress id="progressBar" value="0" max="100"></progress>',
                showConfirmButton: false,
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                stopKeydownPropagation: true
            });
            try {
                this.data.office = this.$route.params._id;
                this.data._id = this.$route.params.object_id;
                await store.updateData("contributor", [this.data], true, "misalud");
                Swal.fire({
                icon: "success",
                title: "Aportante actualizado"
                }).then(() => {
                    this.$router.go(-1);
                });
            } catch (error) {
                Swal.fire({
                    icon: "error",
                    title: "Error al actualizar el Aportante"
                }).then(() => {
                    this.$router.go(-1);
                });
            }
        },
        rollbackLink() {
            Swal.fire({
                title: "¿Abandonar el Formulario?",
                showCancelButton: true,
                confirmButtonText: `Abandonar`,
                cancelButtonText: "Quedarse"
            }).then(result => {
                if (result.isConfirmed) {
                this.$router.go(-1);
                }
            });
        }
    },
  }
</script>