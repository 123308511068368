<template>
    <v-card
        class="mx-auto mb-12"
        style="background-color: unset;"
        elevation="0"
    >
        <h2 class="text-center">Representante Legal</h2>
        <v-form
            class="px-5"
            ref="form"
            v-model="valid"
            lazy-validation
        >
            <v-select
                v-model="legalIDType"
                :items="legalIDTypes"
                :rules="legalIDTypeRules"
                label="Tipo de Documento"
                required
            ></v-select>

            <v-text-field
                v-model="legalID"
                :rules="legalIDRules"
                type="number"
                label="Número de Documento"
                required
            ></v-text-field>

            <v-text-field
                v-model="legalName"
                :counter="legalNameMax"
                :maxlength="legalNameMax"
                :rules="legalNameRules"
                label="Nombres y Apellidos"
                required
            ></v-text-field>

            <v-text-field
                v-model="legalPhone"
                type="number"
                label="Teléfono"
            ></v-text-field>

            <v-text-field
                v-model="legalEmail"
                :counter="legalEmailMax"
                :maxlength="legalEmailMax"
                label="Correo Electrónico"
            ></v-text-field>
        </v-form>
    </v-card>
</template>

<script>
import Storage from "@/modules/storage";
import { titleCase } from "@/modules/tools";
const store = new Storage();

export default {
    data() {
        return {
            mountedFinish: false,
            valid: false,
            legalIDType: '',
            legalIDTypes: [],
            legalIDTypeRules: [
                v => !!v || 'El Tipo de Documento es requerido',
            ],
            legalID: '',
            legalIDMin: 0,
            legalIDMax: 1,
            legalIDRules: [
                v => !!v || 'El Número de Documento es requerido',
                v => (v && v >= this.legalIDMin) || `El Número de Documento debe ser un número superior a ${this.legalIDMin}`,
                v => (v && v <= this.legalIDMax) || `El Número de Documento debe ser un número inferior a ${this.legalIDMax}`,
            ],
            legalName: '',
            legalNameMin: 0,
            legalNameMax: 1,
            legalNameRules: [
                v => !!v || 'El nombre es requerido',
                v => (v && v.length >= this.legalNameMin) || `El nombre debe tener al menos ${this.legalNameMin} letras`,
                v => (v && v.length <= this.legalNameMax) || `El nombre no debe superar las ${this.legalNameMax} letras`,
            ],
            legalPhone: '',
            legalEmail: '',
            legalEmailMax: 1,
        }
    },
    props: ['validate', 'default'],
    watch: {
        validate: function(value) {
            if (value) {
                this.$refs.form.validate();
            }
        },
        default: function(value) {
           if (value) {
               const checkMount = setInterval(() => {
                   if (this.mountedFinish) {
                        this.legalIDType = value.legalIDType;
                        this.legalID = value.legalID;
                        this.legalName = value.legalName;
                        this.legalPhone = value.legalPhone;
                        this.legalEmail = value.legalEmail;
                        clearInterval(checkMount);
                   }
               }, 100);
            } 
        }
    },
    updated() {
        this.$emit('valid', {
            step: 3,
            valid: this.valid,
            data: {
                legalIDType: this.legalIDType,
                legalID: parseInt(this.legalID),
                legalName: this.legalName,
                legalPhone: parseInt(this.legalPhone),
                legalEmail: this.legalEmail
            }
        })
    },
    async mounted() {
        const schemaData = await store.getData("schema");
        schemaData.forEach(schema => {
            if (schema.store === "contributor") {
                this.legalIDTypes = schema.data.legalIDType.enum;
                this.legalIDMin = schema.data.legalID.min;
                this.legalIDMax = schema.data.legalID.max;
                this.legalNameMin = schema.data.legalName.min;
                this.legalNameMax = schema.data.legalName.max;
                this.legalEmailMax = 1024;
            }
        });
        this.mountedFinish = true;
    },
}
</script>